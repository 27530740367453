<template>
  <div class="box">
    <div class="outBox">
      <div class="centerList leftFlex">
        <div class="back_box">
          <div class="back" @click="$router.go(-1)">
            <i class="el-icon-arrow-left"></i>
            返回
          </div>
        </div>
        <div class="user-info">
          <div class="upload" @click="isShowUpload">
            <img :src="require('assets/images/upload1.png')" alt="" />
            <span>上传新作品</span>
          </div>
          <div
            class="AuthorizationList"
            @click="showDialogAuthorizationList = true"
          >
            <img :src="require('assets/images/view.png')" alt="" />
            <span>授权列表</span>
          </div>
          <div class="info">
            <img
              :src="
                ($store.state.user_info && $store.state.user_info.headImg) ||
                require('assets/images/personal-settings/user.png')
              "
              alt=""
            />
            <div class="left">
              <div class="collection">
                作品集 (ID:{{ $store.state.user_info.number }})
              </div>
              <div class="focus">
                <span>{{ userInfo.praiseNum }}获赞</span> |
                <span>{{ userInfo.foucsNum }}关注</span> |
                <span>{{ userInfo.fansNum }}粉丝</span>
              </div>
            </div>
          </div>
          <div class="cards">
            <div class="works-number">
              <div class="label">
                <div class="title">作品数量</div>
                <div class="value">
                  {{ userInfo.portfolioNum }}篇作品{{ userInfo.wordTotal }}字
                </div>
              </div>
              <img :src="require('assets/images/works-number.png')" alt="" />
            </div>
            <div
              class="works-rank"
              @click="
                $router.push({
                  name: 'WorksRank',
                  params: {
                    index: '1',
                  },
                })
              "
            >
              <div class="label">
                <div class="title">作品榜</div>
                <div class="value">第{{ userInfo.rank }}位</div>
              </div>
              <img :src="require('assets/images/works-rank.png')" alt="" />
            </div>
            <div class="earnings">
              <div class="label">
                <div class="title">版权收益</div>
                <div class="value">{{ userInfo.copyrightIncome }}元</div>
              </div>
              <img :src="require('assets/images/earnings.png')" alt="" />
            </div>
            <div
              class="earnings-rank"
              @click="
                $router.push({
                  name: 'AuthorRank',
                  params: {
                    index: '2',
                  },
                })
              "
            >
              <div class="label">
                <div class="title">作者榜</div>
                <div class="value">第{{ userInfo.incomeRank }}位</div>
              </div>
              <img :src="require('assets/images/earnings-rank.png')" alt="" />
            </div>
          </div>
        </div>
        <div class="mian">
          <div class="sidebar">
            <!-- <div class="item" @click="findAll" :class="{ 'is-active': currentIndex == -1 }">全部</div>
        <div
          class="item"
          :class="{ 'is-active': currentIndex == index }"
          v-for="(item, index) in btnArr"
          :key="item.id"
          @click="findListById(item.id, index)"
        >
          {{ item.title }}
        </div> -->
            <el-menu
              default-active="2"
              unique-opened
              ref="elMenu"
              class="el-menu-vertical-demo"
            >
              <el-menu-item index="0" @click="findAll">全部</el-menu-item>
              <el-submenu
                :index="item.title"
                v-for="item in sidebarArr"
                :key="item.id"
                @click.native="
                  () => {
                    currentTitle = item.title;
                    getChild(item);
                  }
                "
              >
                <template slot="title">{{ item.title }}</template>
                <el-menu-item
                  :index="item1.id + ''"
                  v-for="item1 in item.children"
                  @click.native.stop="findListById(item1.id)"
                  :key="item1.id"
                  >{{ item1.title }}</el-menu-item
                >
              </el-submenu>
            </el-menu>
          </div>
          <div class="content">
            <!-- <div class="centerList-title">
          <el-button class="copyrightIncome-item" @click="isShowUpload" type="text"
            >上传版权文字作品</el-button
          >
          已上传授权作品列表
        </div> -->

            <div class="title-option">
              <div class="sort">
                <div
                  class="sort_item"
                  v-for="(item, index) in sortArr"
                  :key="item.id"
                  @click="sortClick(item, index)"
                >
                  <span :class="{ 'is-active': currentSortIndex == index }">{{
                    item.title
                  }}</span>
                  <i
                    class="right-item-icon"
                    :class="item.sort == true ? 'up' : 'down'"
                  ></i>
                </div>
              </div>
            </div>
            <div class="tableContent">
              <div
                class="table_item"
                v-for="(item, index) in tableData"
                :key="item.id"
              >
                <img :src="require('assets/images/wps-white.png')" alt="" />

                <div class="main">
                  <div class="title">主标题:{{ item.filename }}</div>
                  <div class="classfiy">{{ item.portfolioType }}</div>
                  <div class="info">
                    <!-- <div class="worker">作者: {{ item.author }}</div> -->
                    <div class="time">
                      创作时间:
                      {{
                        item.publishTime && item.publishTime.substring(0, 10)
                      }}
                    </div>
                    <div class="pirce">
                      购买价格: {{ item.price || 0 }}
                      {{
                        item.filetype == 1 && item.setPrice == 0 ? "字符" : "元"
                      }}
                    </div>
                    <div class="pirce">
                      授权价格: {{ item.authorizePrice || 0 }}
                      {{
                        item.filetype == 1 && item.setPrice == 0 ? "字符" : "元"
                      }}
                    </div>
                    <div class="cashIncome">收益: {{ item.cashIncome }}</div>
                    <el-popover
                      popper-class="option-popper"
                      placement="bottom"
                      trigger="click"
                    >
                      <div
                        class="option_item"
                        @click="detailsClick(item, index)"
                      >
                        查看
                      </div>
                      <div class="option_item" @click="editCilck(item)">
                        修改
                      </div>
                      <!-- <div class="option_item" v-if="spliceMd5(item.md5)" @click="goren(i)">认领</div> -->
                      <!-- <div class="option_item" v-else @click="error_flag = true">纠错</div> -->
                      <!-- <div
                    class="option_item"
                    v-if="item.isParse == 1 && item.status != 1"
                    @click="handleShelves(item)"
                  >
                    上架
                  </div>
                  <div
                    class="option_item"
                    v-if="item.isParse == 1 && item.status == 1"
                    @click="handleShelves(item)"
                  >
                    下架
                  </div> -->
                      <div
                        class="option_item"
                        v-if="item.isParse == 0"
                        @click="handleDelete(item.id)"
                      >
                        删除
                      </div>
                      <img
                        class="more"
                        slot="reference"
                        :src="require('assets/images/headers/more.png')"
                        alt=""
                      />
                    </el-popover>
                  </div>
                </div>
                <span
                  :class="[
                    'states',
                    item.status == 2
                      ? 'orange'
                      : item.isParse == 1
                      ? item.status === 1
                        ? 'green'
                        : 'orange'
                      : item.isParse == 0
                      ? 'gray'
                      : item.isParse == 2
                      ? 'eparsed'
                      : item.isParse == 3
                      ? 'hasparse'
                      : '',
                  ]"
                >
                  {{
                    item.status == 2
                      ? "已下架"
                      : item.isParse == 1
                      ? item.status === 1
                        ? "已上架"
                        : "已下架"
                      : item.isParse == 0
                      ? "解析中"
                      : item.isParse == 2
                      ? "解析失败"
                      : item.isParse == 3
                      ? "已存在"
                      : ""
                  }}
                </span>
              </div>
            </div>

            <div class="no-document" v-show="tableData.length == 0">
              <div class="title">
                <img :src="require('assets/images/han.png')" alt="" />
                版权作品投稿须知
              </div>
              <div class="content_item">
                <div class="requirements">
                  <span class="label">版权作品投稿要件：</span>
                  版权作品投稿要件：（1）需
                  <span
                    style="color: #0871ff; cursor: pointer"
                    @click="
                      $router.push(
                        '/copyrightService/realName/personalRealName'
                      )
                    "
                    >实名认证</span
                  >
                  ；（2）需签署<span
                    style="color: #0871ff; cursor: pointer"
                    @click="ShowCopyrightVisible"
                    >授权委托书</span
                  >；（3）本人合法有效拥有著作权。
                  版权作品定价规则：电子书由版权作者
                </div>
                <div class="rules">
                  <span class="label">版权作品定价规则：</span>
                  电子书由版权作者自主确定购买价格、授权价格，或者免费。本科、硕士、博士学位论文和博士后出站报告的购买价格固定为5元、7元、9元、12元，授权价格由版权作者自主确定，或者免费；模板由版权作者自主确定购买价格，或者免费。
                </div>
                <div class="constitute">
                  <span class="label">版权收益构成：</span>
                  （1）用户支付的购买付费，仅供购买用户个人使用；（2）用户支付的授权费用，委托深圳写手智能科技公司授予购买用户信息网络传播权；（3）原创篇、段、句在溯源、数据分析等功能应用中，获得深圳写手智能公司转支付的字符收益。
                </div>
                <div class="rules">
                  <span class="label">版权收益分配规则：</span>
                  版权收益依法依规扣除相应的税费后，版权作者获得版权收益的97%，深圳写手智能科技公司获得版权收益的3%作为信息服务费用。
                </div>
              </div>
            </div>
            <el-pagination
              v-show="tableData.length != 0"
              layout="total, prev, pager, next, jumper"
              :pager-count="5"
              :current-page="pageNum"
              :page-size="pageSize"
              @current-change="currentChange"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>

        <!-- 修改创造时间弹窗 -->
        <el-dialog
          title="修改创作时间"
          :modal="false"
          @close="editClose"
          width="30%"
          :visible.sync="dialogVisible"
        >
          <div class="text">
            创作时间是版权作最核心要素，我们是非常谨慎，敬请体谅。
          </div>
          <el-form
            :model="dialogRuleForm"
            label-position="left"
            label-width="80px"
          >
            <el-form-item label="现时间"> {{ currentdate }} </el-form-item>
            <el-form-item label="拟修改">
              <el-date-picker
                v-model="dialogRuleForm.dialogDate"
                style="width: 100%"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="请输入修改时间"
              >
              </el-date-picker>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="amendPublishTime(dialogRuleForm)"
              >提交</el-button
            >
            <el-button @click="dialogVisible = false">取消</el-button>
          </span>
        </el-dialog>

        <!-- 修改作者 -->
        <el-dialog title="修改作者" :visible.sync="authorVisible">
          <div class="select">
            <div class="text">作者</div>
            <el-select
              v-model="value1"
              style="width: 100%"
              placeholder="请选择作者"
            >
              <el-option
                v-for="(item, index) in options"
                :key="index"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>

          <div class="addauthor_box">
            <div class="placeholder"></div>
            <div class="addauthor" @click="inpshow = true">+ 新增作者</div>
          </div>

          <div class="addauthor_box" v-show="inpshow">
            <div class="placeholder"></div>
            <div class="addauthor">
              <el-input
                @change="authorChange"
                class="authorinp"
                v-model="input"
                placeholder="请输入作者"
              ></el-input>
            </div>
          </div>

          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="authorVisible = false"
              >确定</el-button
            >
            <el-button @click="authorVisible = false">取消</el-button>
          </span>
        </el-dialog>

        <!-- 修改授权价格弹窗 -->
        <el-dialog
          class="priceVisible"
          title="修改授权价格"
          width="25%"
          :visible.sync="priceVisible"
        >
          <div class="cash">
            授权类型：
            <div class="text">
              依据《中华人民共和国著作权法》授予本人作品的“信息网络传播权”
            </div>
          </div>
          <div class="shape">授权价格:</div>

          <div class="checked">
            <div class="checked_box">
              <el-checkbox v-model="priceChecked" @change="handleChecked(1)">
                <span class="checkbox_content">
                  系统默认，付费标准：文档按全文字符数、图片按1
                  元/张、音频按3元/个、视频按5元/个授权收费。
                </span>
              </el-checkbox>
            </div>

            <el-checkbox v-model="priceInputChecked" @change="handleChecked(2)">
              请输入价格(元)
            </el-checkbox>

            <el-input
              type="number"
              v-show="priceInputChecked"
              v-model.number="priceInput"
              @focus="handleChecked(2)"
              placeholder="请输入价格"
            ></el-input>
          </div>

          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="modifyPrice">确 定</el-button>
            <el-button @click="priceVisible = false">取 消</el-button>
          </span>
        </el-dialog>

        <!-- 提交成功 -->
        <div v-if="success_flag" class="success">
          <img :src="require('assets/images/chenggong.png')" />
          <span
            >提交成功，我们将在1-3个工作日内处理完成，出现无法核实等复杂情况时，我们将及时和您沟通。感谢您的支持！</span
          >
        </div>

        <!-- 详细信息 -->
        <el-dialog
          title="详细信息"
          :visible.sync="infoFilesPopup"
          class="addFil"
        >
          <div class="box">
            <span class="boxSpan">文件名：</span>&nbsp;&nbsp;&nbsp;
            <span> {{ valInfo.filename }}</span>

            <img
              @click="downloadFil"
              class="download"
              :src="require('assets/images/info/download.png')"
              alt=""
            />
          </div>
          <div class="box">
            <span class="boxSpan">序号：</span>&nbsp;&nbsp;&nbsp;
            <span> {{ valInfo.index }}</span>
          </div>
          <div class="box">
            <span class="boxSpan">作者：</span>&nbsp;&nbsp;&nbsp;
            <span>
              {{ valInfo.author }}
            </span>
          </div>
          <div class="box">
            <span class="boxSpan">收益：</span>&nbsp;&nbsp;&nbsp;
            <span>
              {{ valInfo.cashIncome }}元 / {{ valInfo.characterIncome }} 字符
            </span>
          </div>
          <div class="box">
            <span class="boxSpan">创作时间：</span>&nbsp;&nbsp;&nbsp;
            <span>
              {{ valInfo.publishTime }}
            </span>
          </div>
          <div class="box">
            <span class="boxSpan">授权价格：</span>&nbsp;&nbsp;&nbsp;
            <span>
              {{ valInfo.authorizePrice }}
              {{
                valInfo.filetype == 1 && valInfo.setPrice == 0 ? "字符" : "元"
              }}
            </span>
          </div>
        </el-dialog>

        <!-- 版权真实性承诺 -->
        <el-dialog
          title="原创文字作品授权书"
          :visible.sync="copyrightVisible"
          width="80%"
          class="copyrightVisible"
        >
          <div class="text">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;本人承诺所有上传的文字作品均拥有合法有效的原创版权，或者属于在写手智能科技公司相关网站、软件平台上首次发表。<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;授权深圳写手智能科技有限公司，在其官方网站、“成语”办公软件、反抄袭网等系列产品中，免费使用本人的原创文字作品。授权类型为《中华人民共和国著作权法》规定的信息网络传播权。授权时间至本人提出解除授权时截止。<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;委托深圳写手智能科技有限公司，按照本人确定价格，将原文档出售给写手用户，付费购买的写手用户可以全文阅读、下载原文档、存入智能网盘。<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;委托深圳写手智能科技有限公司，按照本人确定价格，将原文档的信息网络传播权转授权给写手用户，并代理出具授权书。<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;依如下约定获得收益：1.写手用户支付的购买费用。2.写手用户支付的授权费用。3.获得写手用户在使用溯源、数据分析等功能所支付的字符中，属于本人原创篇章、段落、句子的字符收益。<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;写手智能科技有限公司在全网或其他领域，发现有涉及本人文字作品被侵权等问题，第一时间免费对侵权行为取证存证，及时告知本人处理。<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;特此授权！
          </div>

          <div class="signature_box">
            <div class="signature_text">承诺人签名</div>
            <div
              v-show="!authorSignatureImg"
              class="signature"
              @click="handleClickSignature"
            >
              点击签名
            </div>
          </div>
          <img
            @click="handleClickSignature"
            v-show="authorSignatureImg"
            :src="authorSignatureImg"
            class="authorSignatureImg"
          />
          <div class="commitment_box">
            <div class="commitmentText">承诺时间</div>
            <div class="commitmentDate">{{ commitmentCurrentdate }}</div>
            <div class="commitmentTime">{{ currenttime }}</div>
          </div>

          <span slot="footer" class="dialog-footer" style="text-align: center">
            <el-button type="primary" size="small" @click="handleAddCommitment"
              >确定</el-button
            >
            <el-button @click="copyrightVisible = false" size="small"
              >取消</el-button
            >
          </span>
        </el-dialog>

        <!-- 签名画布组件 -->
        <el-dialog
          title="二维码"
          class="qrcode"
          :close-on-click-modal="false"
          @close="qrcodeClose"
          :visible.sync="signaTureShow"
        >
          <div class="content">
            <div class="notes">扫二维码签名</div>
            <div class="imgBox">
              <div ref="qrcode_box"></div>
            </div>
          </div>
        </el-dialog>

        <el-dialog
          :visible.sync="uploadVisible"
          :close-on-press-escape="false"
          :close-on-click-modal="false"
          class="uploadVisible"
          @close="uploadClose"
          :modal="false"
          width="700px"
        >
          <el-form
            ref="addForm"
            :rules="addRules"
            label-position="right"
            :model="form"
            label-width="100px"
          >
            <el-form-item label="选择类型">
              <el-button
                plain
                v-for="(item, index) in btnArr"
                @click="btnFindChild(item, index)"
                :key="item.id"
                style="margin: 5px 5px"
                :class="{ isSelect: currentBtnIndex === index }"
                >{{ item.title }}</el-button
              >
            </el-form-item>

            <div class="nation" v-if="className == '学术论文'">
              <el-radio-group v-model="nationRadio">
                <el-radio
                  v-for="item in nationArr"
                  :label="item.id"
                  :key="item.id"
                  >{{ item.name }}</el-radio
                >
              </el-radio-group>
            </div>

            <el-form-item
              label="学位类别"
              prop="disciplineRadio"
              v-if="className == '学位论文'"
            >
              <el-radio-group v-model="form.disciplineRadio">
                <el-radio
                  v-for="item in disciplineCategory"
                  @click.native="findThemeClick(item, $event)"
                  :label="item.id"
                  :key="item.id"
                  >{{ item.title }}</el-radio
                >
              </el-radio-group>
            </el-form-item>
            <el-form-item
              label="模板类别"
              prop="templateRadio"
              v-if="className == '模板'"
            >
              <el-radio-group v-model="form.templateRadio">
                <el-radio
                  v-for="item in templateCategory"
                  @click.native="changeDiscipline(item.price)"
                  :label="item.labelId"
                  :key="item.id"
                  >{{ item.title }}</el-radio
                >
              </el-radio-group>
            </el-form-item>
            <el-form-item
              label="学科类别"
              prop="themeRadio"
              v-if="className == '学位论文' || className == '学术论文'"
            >
              <div class="theme">
                <el-radio-group v-model="form.themeRadio">
                  <el-radio
                    v-for="item in themeCategory"
                    :label="item.labelId"
                    :key="item.id"
                    >{{ item.title }}</el-radio
                  >
                </el-radio-group>
              </div>
            </el-form-item>

            <el-row>
              <el-col :span="24">
                <el-form-item label="作者" prop="author">
                  <el-input
                    v-model="form.author"
                    placeholder="多个作者之间用逗号隔开"
                    maxlength="50"
                  >
                    <i
                      slot="suffix"
                      style="color: #0871ff"
                      title="作者"
                      class="el-input__icon el-icon-info"
                    ></i
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item :label="titleLabel" prop="fileTitle">
                  <el-input v-model="form.fileTitle">
                    <i
                      slot="suffix"
                      style="color: #0871ff"
                      :title="titleLabel"
                      class="el-input__icon el-icon-info"
                      maxlength="50"
                    ></i>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item
                  label="发表地"
                  v-if="className != '出版图书' && className != '学位论文'"
                  prop="wordType"
                >
                  <el-select
                    v-model="form.wordType"
                    class="wordTypeSelect"
                    style="width: 22%"
                    :disabled="isFirst"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in docs"
                      :key="item.name"
                      :label="item.name.substr(0, 2)"
                      :value="item.name"
                    >
                    </el-option>
                  </el-select>
                  <el-input
                    style="width: 58%"
                    class="wordTypeInput"
                    :disabled="isFirst"
                    v-model="form.wordTypeConten"
                  ></el-input>
                  <el-checkbox
                    v-model="isFirst"
                    @change="handleIsFirst"
                    style="
                      border-left: none;
                      border-radius: 0 4px 4px 0;
                      border-color: #dcdfe6;
                    "
                    border
                    label="首次发表
          "
                  ></el-checkbox>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item :label="timeLabel" prop="publishTime">
                  <el-date-picker
                    style="width: 100%"
                    v-model="form.publishTime"
                    value-format="yyyy-MM-dd"
                    type="date"
                    placeholder="选择日期"
                  >
                    <i slot="suffix" class="el-input__icon el-icon-info"></i>
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="出版地(社)"
                  v-if="className == '出版图书'"
                  prop="source"
                >
                  <el-input v-model="form.source"
                    ><i
                      slot="suffix"
                      style="color: #0871ff"
                      title="出版地:出版社"
                      class="el-input__icon el-icon-info"
                    ></i>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="9">
                <el-form-item label="购买价格" prop="price">
                  <el-input
                    v-model.number="form.price"
                    class="priceClass"
                    :disabled="className == '学位论文' || priceIsFree"
                  >
                    <i
                      slot="suffix"
                      style="color: #0871ff"
                      title="购买价格"
                      class="el-input__icon el-icon-info"
                    ></i
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="3">
                <el-checkbox
                  @change="zeroPrice"
                  v-model="priceIsFree"
                  style="
                    border-left: none;
                    border-radius: 0 4px 4px 0;
                    border-color: #dcdfe6;
                  "
                  border
                  label="免费
          "
                ></el-checkbox
              ></el-col>
              <el-col :span="9">
                <el-form-item
                  label="授权价格"
                  v-if="className != '模板'"
                  prop="authorizePrice"
                >
                  <el-input
                    class="priceClass"
                    :disabled="authorizePriceIsFree"
                    v-model.number="form.authorizePrice"
                  >
                    <i
                      slot="suffix"
                      style="color: #0871ff"
                      title="授权价格"
                      class="el-input__icon el-icon-info"
                    ></i
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="3">
                <el-checkbox
                  v-if="className != '模板'"
                  @change="zeroPrice"
                  v-model="authorizePriceIsFree"
                  style="
                    border-left: none;
                    border-radius: 0 4px 4px 0;
                    border-color: #dcdfe6;
                  "
                  border
                  label="免费
          "
                ></el-checkbox
              ></el-col>
            </el-row>

            <el-row class="customBorder">
              <el-col :span="24">
                <el-form-item label="上传文档">
                  <el-upload
                    class="upload-demo"
                    action=""
                    :show-file-list="false"
                    :http-request="customUploadFile"
                  >
                    <span class="click-upload">{{ uploadText }}</span>
                  </el-upload></el-form-item
                >
              </el-col>
            </el-row>
            <el-row class="customWidth" v-if="className == '出版图书'">
              <el-col :span="20">
                <el-form-item label="上传附件">
                  <el-upload
                    class="upload-power"
                    action=""
                    :show-file-list="false"
                    :http-request="customPowerFile"
                  >
                    <span class="click-upload">{{ uploadImgText }}</span>
                  </el-upload></el-form-item
                >
              </el-col>
              <el-col :span="4">
                <el-button @click="demoDialog = true">版权页样本</el-button>
              </el-col>
            </el-row>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="confirmNewWorksUpload"
              >确 定</el-button
            >
            <el-button @click="uploadVisible = false">取 消</el-button>
          </span>
        </el-dialog>
        <el-dialog title="版权页样本" width="540px" :visible.sync="demoDialog">
          <img :src="require('assets/images/power_demo.jpg')" alt="" />
        </el-dialog>
        <!-- 修改作品信息弹窗 -->
        <el-dialog
          title="修改作品信息"
          class="editDialog"
          @close="editDialogClose"
          :visible.sync="editWorksInfo"
          width=""
        >
          <el-form
            ref="editForm"
            :rules="editRules"
            :model="editForm"
            label-width="80px"
          >
            <el-form-item label="主标题" prop="filename">
              <el-input v-model="editForm.filename" disabled></el-input>
            </el-form-item>
            <el-form-item label="作者" prop="author">
              <el-input v-model.trim="editForm.author"></el-input>
            </el-form-item>
            <el-form-item label="出版时间" prop="publishTime">
              <el-date-picker
                v-model="editForm.publishTime"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="文献类型" prop="wordType">
              <el-select v-model="editForm.wordType" placeholder="请选择">
                <el-option
                  v-for="item in docs"
                  :key="item.name"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <!-- <el-form-item label="来源" prop="source">
          <el-input v-model.trim="editForm.source"></el-input>
        </el-form-item> -->
            <el-form-item label="价格" prop="price">
              <el-input v-model.number="editForm.price"></el-input>
            </el-form-item>
            <el-form-item label="授权价格" prop="authorizePrice">
              <el-input v-model.number="editForm.authorizePrice"></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="editWorksInfoClick"
              >确 定</el-button
            >
            <el-button @click="editWorksInfo = false">取 消</el-button>
          </span>
        </el-dialog>
        <!-- 版权文字作品上传须知弹窗 -->
        <el-dialog
          v-dialogDrag
          title="版权文字作品上传须知"
          :visible.sync="uploadNoticeVisible"
          class="uploadNotice"
          :modal="false"
        >
          <div class="content-text">
            <div class="title">1.上传版权文字作品的三大好处。</div>
            <div class="text_box">
              通过版权作品自主设定价格，获得现金、字符包等收益。
            </div>
            <div class="sequence_txt">
              <span>(1)</span>
              保护个人知识产权。在写手智能科技官网上传作品，可以免费获得时间戳认证，成为应对版权侵权的铁证。写手智能科技在全网检索中，如发现侵权行为，会及时告知版权人，保护个人知识产权。
            </div>
            <div class="sequence_txt">
              <span>(2)</span>
              持续获得反抄袭的字符收入。文字作品的最早原创者，在所有溯源中支付的字符数，涉及到篇、段、句的层面，都将直接获得重复字段的字符数。
            </div>

            <div class="text_box">
              例如：微信公众号知名博主王中锤，耗费三月有余创作了5000字的《时间就是第一生产力》，文笔优美、金句频频，随后被各种花式洗稿、拆分得七零八落，投诉、举报乃至诉讼均难以奏效。王中锤将《时间就是第一生产力》，上传至写手智能科技公司版权作品数据库。随后，某媒体机构在审核其拟刊发的3000字的《时间生产力》一文时，向写手智能科技公司支付了3000个字符，溯源发现其中2800字符均出自于王中锤的《时间就是第一生产力》，则王中锤可以自写手智能科技公司转获得2800字符，该字符可以直接提现。目前，字符提现价格为0.25元/千字。
            </div>

            <div class="sequence_txt">
              <span>(3)</span>
              持续获得购买用户支付的费用。用户需要阅读、下载全文，需要向版权作者支付购买费用。
            </div>

            <div class="title">2.上传版权文字作品的必备条件</div>
            <div class="sequence_txt">
              <span>(1)</span>
              拥有文字作品真实有效的版权；
            </div>
            <div class="sequence_txt">
              <span>(2)</span>
              文字作品载体为doc/docx/wps格式。
            </div>

            <div class="text_box">
              第一要素，作者为实名认证用户名，不可修改.
            </div>

            <div class="text_box">
              第二要素， 第二要素，文件名，选择要上传的文件后点击文件名可修改。
            </div>
            <div class="text_box">
              第三要素，文献标识符。选择要上传的文件后点击文献类型可修改。
            </div>
            <div class="text_box">
              第四要素，来源。包括发表期刊、出版社、首次发布网站等,默认为写手智能科技数据库，选择要上传的文件后点击来源可修改。
            </div>
            <div class="text_box">
              第五要素，创作时间。首次发表时间、最后成稿时间。默认为当前日期，选择要上传的文件后点击创作日期可修改。
            </div>
          </div>
        </el-dialog>
        <!-- 认证弹窗 -->
        <div v-if="ren_flag" class="claim">
          <img
            @click="close"
            class="close"
            :src="require('assets/images/close.png')"
          />
          <div class="renzheng">
            <img :src="require('assets/images/renzheng.png')" />
            <span>认领版权需要实名认证</span>
          </div>
          <div class="xuanze">
            <div @click="gorealName" class="yes">现在认证</div>
            <div class="xian"></div>
            <div @click="close" class="no">取消</div>
          </div>
        </div>
        <!-- 认领弹窗 -->
        <div v-if="claimn_flag" class="claimn">
          <div class="claimnNav">
            <div class="title">
              <img :src="require('assets/images/renling.png')" />
              <span>待认领版权-认领方式</span>
            </div>
            <img
              @click="closecl"
              class="title_x"
              :src="require('assets/images/close.png')"
            />
          </div>
          <div class="wangzhi">
            <input
              @click="dian($event)"
              style="cursor: pointer"
              id="websit"
              type="radio"
              name="proof"
            />
            <label for="websit">网址佐证</label>
          </div>
          <div class="wangzhi">
            <input
              @click="dian($event)"
              style="cursor: pointer"
              id="photo"
              type="radio"
              name="proof"
            />
            <label for="photo">照片或资料佐证</label>
          </div>
          <span class="w-title">网址佐证</span>
          <input v-model="website" class="shuruw" placeholder="请输入网址" />
          <span class="w-title">照片或资料佐证</span>
          <input
            id="photos"
            type="file"
            @change="realUpload"
            class="shuruw"
            name="frontFile"
            ref="backFile1"
            style="opacity: 0"
          />
          <div v-if="flie == ''" class="file-tip" @click="uploadFile1">
            点击上传照片或资料佐证
          </div>
          <div
            v-if="flie != ''"
            class="file-tip"
            @click="uploadFile1"
            v-text="flie"
          ></div>
          <span class="w-title">备注</span>
          <textarea v-model="r_remark" placeholder="请输入备注" />
          <div class="botton">
            <el-button @click="submit" type="primary">提交</el-button>
            <el-button @click="closecl">取消</el-button>
          </div>
        </div>
        <!-- 纠错弹窗 -->
        <div v-if="error_flag" class="claimn">
          <div class="claimnNav">
            <div class="title">
              <img :src="require('assets/images/lanbanquan.png')" />
              <span>版权纠错</span>
            </div>
            <img
              @click="closeer"
              class="title_x"
              :src="require('assets/images/close.png')"
            />
          </div>
          <span class="w-title">著作权人有误</span>
          <input
            v-model="owner"
            class="shuruw"
            placeholder="请输入准确著作权人"
          />
          <span class="w-title">网址佐证</span>
          <input v-model="websiter" class="shuruw" placeholder="请输入网址" />
          <span class="w-title">照片或资料佐证</span>
          <input
            @change="realUpload"
            id="file"
            type="file"
            class="shuruw"
            name="frontFile"
            ref="backFile1"
            style="opacity: 0"
          />
          <div v-if="flie == ''" class="file-tip" @click="1">点击上传</div>
          <div
            v-if="flie != ''"
            class="file-tip"
            @click="uploadFile1"
            v-text="flie"
          ></div>
          <span class="w-title">备注</span>
          <textarea v-model="remark" placeholder="请输入备注" />
          <div class="botton">
            <el-button @click="submiter" type="primary">提交</el-button>
            <el-button @click="closeer">取消</el-button>
          </div>
        </div>
        <!-- 提交认领成功 -->
        <div v-if="succ_flag" class="success">
          <img src="require('assets/images/chenggong.png')" />
          <span
            >提交成功，我们将在1-3个工作日内处理完成，出现无法核实等复杂情况时，我们将及时和您沟通。感谢您的支持！</span
          >
        </div>
        <el-dialog
          title="授权列表"
          width="90%"
          :visible.sync="showDialogAuthorizationList"
        >
          <el-tabs v-model="activeName">
            <el-tab-pane label="我的授权" name="1">
              <record />
            </el-tab-pane>
            <el-tab-pane label="授权我的" name="0">
              <recordCopy />
            </el-tab-pane>
          </el-tabs>
        </el-dialog>
      </div>
      <div class="right recommendRightList">
        <recommend />
      </div>
    </div>
    <div class="commonFooter_box">
      <commonFooter />
    </div>
  </div>
</template>

<script>
import {
  getList,
  publishTime,
  updatePrice,
  updateShelves,
  commitment,
  addCommitment,
  thirdpart,
  delWorks,
  worksTypeList,
  workerInfo,
  addNewFile,
  updateFileInfo,
  menuSearchByType,
  tempSignatureCheck,
} from "api/copyrightIncome";
import qs from "qs";
import recommend from "components/recommend/recommend.vue";
import record from "./record";
import recordCopy from "./recordCopy";
import QRCode from "qrcodejs2";
import commonFooter from "components/footer/commonFooter.vue";
import { apply, error } from "api/included";
import { upload, userPortfolioInfo } from "api/common";
import { downloadFile } from "api/copyrightService";
// import Signature from "components/Signature";
// import UploadBigFile from "components/disk/CopyrightIncomeUploadCopy.vue";
export default {
  name: "centerList",
  components: {
    // Signature,
    recommend,
    commonFooter,
    record,
    recordCopy,
    // UploadBigFile,
  },
  data() {
    var checkPrice = (rule, value, callback) => {
      if (value === "") {
        return callback(new Error("价格不能为空"));
      }

      if (!Number.isInteger(value)) {
        callback(new Error("请输入数字值"));
      } else {
        if (value < 0 || value > 30) {
          callback(new Error("价格最大为30,最小为1"));
        } else {
          if (String(value).indexOf(".") != -1) {
            callback(new Error("价格只能为整数"));
          } else {
            callback();
          }
        }
      }
    };

    // const selectDate = new Date(cur + " 00:00:00").getTime();
    var checkDate = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请选择时间"));
      }
      const selectDate = new Date(value + " 00:00:00").getTime();
      const currentDate = new Date().getTime();
      if (currentDate < selectDate) {
        callback(new Error("出版时间不能大于当前时间"));
      } else {
        callback();
      }
    };
    return {
      options: [
        {
          value: 5,
          label: "小明",
        },
      ],
      // signaTureShow: false,
      showDialogAuthorizationList: false,
      authorizePriceIsFree: false,
      priceIsFree: false,
      isFirst: false,
      currentPrice: 0,
      form: {
        wordType: "",
      }, //上传表单绑定
      editForm: {}, //修改表单绑定
      sortArr: [
        //排序数组
        {
          title: "创作时间",
          sort: false, //true 升序 false降序
          id: 1,
        },
        {
          title: "购买/授权价格",
          sort: true,
          id: 2,
        },
        {
          title: "收益",
          sort: true,
          id: 3,
        },
      ],
      timer: null,
      sidebarArr: [],
      demoDialog: false,
      //文献类型
      docs: [
        {
          name: "期刊J",
          key: "J",
        },
        {
          name: "报纸N",
          key: "N",
        },
        {
          name: "网站OL",
          key: "OL",
        },
        {
          name: "平台OL",
          key: "OL",
        },
        {
          name: "其他OL",
          key: "OL",
        },
      ],
      btnArr: [],
      className: "学位论文",
      nationRadio: 1,
      // templateCategory: [],
      // titleLabel: "",
      nationArr: [
        {
          id: 1,
          name: "国内出版",
        },
        {
          id: 2,
          name: "国际出版",
        },
      ],
      themeRadio: "",
      currentTitle: "",
      disciplineRadio: "",
      disciplineCategory: [],
      titleLabel: "模板标题",
      timeLabel: "发表时间",
      themeCategory: [],
      id: "", //作品id
      priceVisible: false, // 修改价格弹窗
      priceChecked: true, //系统默认价格Checked
      priceInputChecked: false, // 输入价格Checked
      priceInput: "", // 输入价格
      authorVisible: false, // 修改作者弹窗
      value1: "", // 选择作者
      inpshow: false, // 显示添加作者输入框
      input: "", // 添加作者输入框
      dialogVisible: false, //修改创造时间弹窗
      currentdate: "", // 当前日期
      //修改创造时间弹窗
      dialogRuleForm: {
        dialogDate: "", // 拟修改
        proofImgUrl: "", // 佐证材料
      },
      proofMaterial: "", // 佐证图片
      tableData: [], //表格数据
      pageNum: 1,
      //一页显示的大小
      pageSize: 10,
      page: 1, //页码
      limit: 10, //一页10条数据
      total: 0, //总条数
      // 作品item
      worksItem: {},
      userInfo: {},
      success_flag: false,
      // 上传文件的name
      file: "",
      // 详情弹窗
      infoFilesPopup: false,
      valInfo: "",
      // 上传作品弹窗
      uploadVisible: false,
      // 版权文字作品上传须知弹窗
      uploadNoticeVisible: false,
      //版权真实性承诺弹窗
      copyrightVisible: false,
      // 作者签名图片
      authorSignatureImg: "",
      // 显示签名组件
      signaTureShow: false,
      // 承诺当期时间
      currenttime: "",
      uploadText: "…仅支持doc.docx.wps文档",
      uploadImgText: "上传图片版权页",
      commitmentCurrentdate: "",
      //类型  网盘传1  版权传2 存证3  溯源4
      columnType: 2,
      // 选择的语境库id
      parentId: "",
      fileType: 1,
      // 语境库
      chooseDataList: [],
      //选择价格id
      choosePriceId: 1,
      // 价格输入框
      demandPriceInput: "",
      // 价格
      choosePriceList: [
        {
          name: "系统默认 (付费标准：文档字符数授权收费)",
          id: 1,
        },
        {
          name: "自定义价格",
          id: 2,
        },
      ],
      fileList: [],
      subIndex: -1,
      //认证弹窗
      ren_flag: false,
      //认领弹窗
      claimn_flag: false,
      //纠错弹窗
      error_flag: false,
      //网址信息
      website: "",
      //资料文件名
      flie: "",
      //认领备注
      r_remark: "",
      //纠错备注
      remark: "",
      //著作权人
      owner: "",
      //纠错网址
      websiter: "",
      //网址佐证标识
      websitFlag: false,
      //资料佐证标识
      photoFlag: false,
      templateCategory: [],

      // 文件路径
      fileUrl: "",
      //纠错资料
      erfile: "",
      succ_flag: false,
      //行id
      rid: 0,

      currentIndex: -1,
      currentSortIndex: 0,
      currentBtnIndex: 0,
      currentId: "",

      editWorksInfo: false, //修改作品信息弹框
      editRules: {
        author: [
          { required: true, message: "请输入作者名称", trigger: "blur" },
        ],
        publishTime: [
          { validator: checkDate, trigger: "change" },
          { required: true.valueOf, message: "请选择时间" },
        ],
        wordType: [
          { required: true, message: "请选择文献类型", trigger: "change" },
        ],
        source: [
          { required: true, message: "请输入出版地(社)", trigger: "blur" },
        ],
        price: [
          { validator: checkPrice, trigger: "blur" },
          { required: true, message: "价格不能为空" },
        ],
        authorizePrice: [
          { validator: checkPrice, trigger: "blur" },
          { required: true, message: "价格不能为空" },
        ],
      },
      addRules: {
        fileTitle: [
          { required: true, message: "主标题不能为空", trigger: "blur" },
        ],
        author: [
          { required: true, message: "请输入作者名称", trigger: "blur" },
        ],
        publishTime: [
          { validator: checkDate, trigger: "change" },
          { required: true, message: "请选择时间" },
        ],
        disciplineRadio: [
          { required: true, message: "请选择学位类别", trigger: "blur" },
        ],
        templateRadio: [
          { required: true, message: "请选择模板类别", trigger: "blur" },
        ],
        themeRadio: [
          { required: true, message: "请选择学科类别", trigger: "blur" },
        ],
        wordType: [
          { required: true, message: "请选择文献类型", trigger: "change" },
        ],
        school: [{ required: true, message: "请输入学校", trigger: "blur" }],
        source: [
          { required: true, message: "出版地(社)不能为空", trigger: "blur" },
        ],
        price: [
          { validator: checkPrice, trigger: "blur" },
          { required: true, message: "价格不能为空" },
        ],
        authorizePrice: [
          { validator: checkPrice, trigger: "blur" },
          { required: true, message: "价格不能为空" },
        ],
      },
      sort: "1", //排序方式
      sortType: "2", //升序还是降序 1为升序

      workerData: {},

      portfolioType: "", //当前选中一级分类
    };
  },
  props: ["isShowRight"],
  methods: {
    /**
     * 按下删除按钮
     * @param { Number } id 作品id
     */
    handleDelete(id) {
      this.$confirm("是否删除该作品", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await this.deleteWorks(id);
          this.getTableList();
        })
        .catch(() => {});
    },
    /**
     * 删除作品请求
     * @param { Number } id 作品id
     */
    async deleteWorks(id) {
      const form = new FormData();
      form.append("id", id);
      const res = await delWorks(form);
      if (res.code == 200) {
        this.$message({
          type: "success",
          message: "删除成功",
          duration: 1000,
        });
      } else {
        this.$message({
          type: "error",
          message: res.message,
          duration: 1000,
        });
      }
    },
    /**
     * MD5 截取 -x  x为0 展示认领  不为0展示纠错
     */
    spliceMd5(md5) {
      if (md5) {
        return md5.split("-")[1] == 0;
      } else {
        return false;
      }
    },
    fileAdded(files) {
      this.fileList = files;
    },
    onFileSuccess(file) {
      if (file.id == this.fileList[this.fileList.length - 1].id) {
        this.getTableList();
      }
    },

    editWorksInfoClick() {
      this.$refs["editForm"].validate(async (vaild) => {
        if (vaild) {
          const res = await updateFileInfo(this.editForm);
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: "修改成功！",
              customClass: "short-message",
              duration: 500,
            });
            this.editWorksInfo = false;
            this.getTableList();
          }
        }
      });
    },
    handleIsFirst(val) {
      console.log("val", val);
      if (val) {
        this.$set(this.form, "wordTypeConten", "成语·智能内容");
        this.$set(this.form, "wordType", "其他OL");
      }
    },
    // el-upload自定义文件上传
    async customUploadFile(files) {
      const { file } = files;
      console.log(file);
      const suffix = file.name.substring(file.name.lastIndexOf(".") + 1);
      if (suffix != "doc" && suffix != "docx") {
        this.$message({
          type: "error",
          message: "请选择docx或doc文件",
          customClass: "short-message",
          duration: 2000,
        });
        return;
      }
      this.uploadText = file.name;
      var fromData = new FormData();
      fromData.append("file", file);
      const res = await upload(fromData);
      if (res.code == 200) {
        this.$set(this.form, "url", res.data);
        this.$message({
          type: "success",
          message: "上传成功",
          customClass: "short-message",
          duration: 2000,
        });
      }
    },
    //确定上传
    sureUpload() {
      this.fileList.forEach((f, fi) => {
        const uploader = this.$refs["uploader"];
        uploader.computeMD5(f);
      });
    },
    handleClickSignature() {
      this.signaTureShow = true;
      this.authorSignatureImg = "";
      const time = new Date().getTime();
      this.$nextTick(() => {
        this.qr = new QRCode(this.$refs.qrcode_box, {
          // text: `https://mall.writemall.com/Signature?time=${time}`,
          text: `https://mall.writemall.com/Signature?time=${time}`,
          // http://192.168.2.232:8082
          width: 130,
          height: 130,
          correctLevel: 3,
          colorDark: "#000000",
          colorLight: "#ffffff",
        });
      });

      this.timer = setInterval(async () => {
        const res = await tempSignatureCheck(qs.stringify({ key: time }));
        if (res.code == 200) {
          if (res.data.status) {
            clearInterval(this.timer);
            this.signaTureShow = false;
            this.authorSignatureImg = res.data.signature;
          }
        }
      }, 2000);
    },
    clearUpload() {
      this.$refs["uploader"].tableData = [];
      this.$refs["uploader"].fileIndex = 0;
      this.fileList = [];
      this.$bus.$emit("clearFileList");
    },
    // 关闭上传弹窗
    handleClose() {
      this.demandPriceInput = "";
      this.choosePriceId = 1;
      this.parentId = this.chooseDataList[0].id;
      this.getTableList();
    },
    handleInput(val) {
      if (val > 30) {
        this.$message("抱歉,最高为30元");
        this.demandPriceInput = 30;
        return;
      } else if (val < 1) {
        this.$message("抱歉,最低为1元");
        this.demandPriceInput = "";
        return;
      }
      if ((val + "").indexOf(".") != -1) {
        this.$message("抱歉,价格不能为小数");
        let price = (val + "").substring(0, (val + "").indexOf("."));
        this.demandPriceInput = price * 1;
      }
    },
    // 获取语境库
    async getThirdpartlist() {
      const res = await thirdpart();
      // console.log(res);
      if (res.code != 200) {
        return this.$message(res.message);
      }

      this.chooseDataList = res.data;
      this.parentId = this.chooseDataList[0].id;
    },

    findAll() {
      // this.currentIndex = -1;
      this.$refs["elMenu"].close(this.currentTitle);
      this.currentId = "";
      this.getTableList();
    },
    editCilck(item) {
      console.log(item);
      this.editForm = JSON.parse(JSON.stringify(item));
      this.editWorksInfo = true;
    },
    //上传新作品
    async confirmNewWorksUpload() {
      if (this.nationRadio == "") {
        return this.$message.error("请选择出版地");
      }

      if (this.className == "学位论文") {
        this.nationRadio = 1;
        if (this.form.disciplineRadio == "" || this.form.themeRadio == "") {
          return this.$message.error("学位类型或学科类型不能为空");
        }
      }
      if (!this.form.url) {
        this.$message.error("请上传文档");
      }
      let labelId = "";
      if (this.className == "学位论文" || this.class == "学术论文") {
        labelId = this.form.themeRadio;
      } else if (this.className == "模板") {
        labelId = this.form.templateRadio;
      } else if (this.className == "出版图书" || this.className == "文字作品") {
        labelId = this.btnArr[this.currentBtnIndex].labelId;
      }

      if (this.form.wordType) {
        this.form.wordType = this.form.wordType.substring(2);
      }
      this.$refs["addForm"].validate(async (valid) => {
        if (valid) {
          const data = {
            portfolioType: this.portfolioType, //一级分类
            // classifyId: this.form.disciplineRadio, //分类id
            // themeId: this.form.themeRadio, //主题id
            switzerland: this.nationRadio, //国际出版 or 国内出版
            labelId: labelId,
            isFirst: this.isFirst ? "1" : "0",
            source: "成语智能内容",
            ...this.form,
          };
          //文件名拼接上文件后缀
          data.fileTitle =
            data.fileTitle + data?.url.substring(data?.url.lastIndexOf("."));

          const res = await addNewFile(data);
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: "上传成功！",
              customClass: "short-message",
              duration: 500,
            });
            this.getTableList();
            this.uploadVisible = false;
            // console.log(res.data);
          } else if (res.code == 415) {
            this.$message({
              type: "info",
              message: "文件已存在",
              customClass: "short-message",
              duration: 500,
            });
          }
          // console.log(data);
        } else {
          this.$message({
            type: "error",
            message: "请填写完整的作品信息",
            customClass: "short-message",
            duration: 500,
          });
        }
      });
    },
    // 签名组件方法
    handleSignature(val) {
      this.signaTureShow = false;
      this.authorSignatureImg = val;
    },

    // 版权真实性承诺
    async handleAddCommitment() {
      if (!this.authorSignatureImg) {
        return this.$message({
          type: "error",
          message: "请签名",
        });
      }

      const data = {
        content:
          "本人承诺所上传作品均为本人真实创作的版权作品，愿意为此承担法律责任",
        autograph: this.authorSignatureImg,
        startTime: this.currentdate + " " + this.currenttime,
      };
      const res = await addCommitment(data);
      // console.log(res);
      if (res.code === 200) {
        this.$message({
          type: "success",
          message: "授权成功",
        });
        // this.fuSublime();
        this.ziSublime();
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
      this.copyrightVisible = false;
    },
    sortClick(item, index) {
      // console.log(item);
      item.sort = !item.sort;
      this.currentSortIndex = index;
      if (item.sort) {
        this.sortType = "1";
      } else {
        this.sortType = "2";
      }
      if (item.title == "创作时间") {
        this.sort = "1";
      } else if (item.title == "购买/授权价格") {
        this.sort = "4";
      } else if (item.title == "收益") {
        this.sort = "3";
      }

      this.getTableList();
    },
    // 校验授权承诺
    async checkAuthorization() {
      const res = await commitment();
      // console.log(res);
      if (res.code === 200) {
        this.uploadVisible = true;
        this.getThirdpartlist();
      } else {
        this.copyrightVisible = true;
      }
      // this.uploadVisible = true;
    },

    // 校验实名和登录
    async isShowUpload() {
      this.$store.commit("CHECK_USER_OPTIONS", {
        afterCheck: () => {
          this.checkAuthorization();
        },
      });
    },
    ShowCopyrightVisible() {
      this.$store.commit("CHECK_USER_OPTIONS", {
        afterCheck: () => {
          this.copyrightVisible = true;
        },
      });
    },
    // 下载
    async downloadFil() {
      // const IFRAME = document.createElement("iframe");
      // IFRAME.style.display = "none";
      // IFRAME.style.height = 0;
      // // src 就是请求服务地址自行修改。
      // // http://fafs.antiplagiarize.com/
      // IFRAME.src = `https://fafs.antiplagiarize.com/${this.valInfo.url}?attname=${this.valInfo.filename}`;
      // document.body.appendChild(IFRAME);
      // setTimeout(() => {
      //   IFRAME.remove();
      // }, 60 * 1000);

      try {
        // eslint-disable-next-line
        DownloadDocument(
          this.valInfo.filename,
          this.$store.state.onlineBasePath + this.valInfo.url
        );
      } catch (e) {
        console.log(e);
      }
    },
    zeroPrice() {
      if (this.priceIsFree) {
        this.form.price = 0;
      }
      if (!this.priceIsFree && this.className == "学位论文") {
        this.form.price = this.currentPrice;
      }
      if (this.authorizePriceIsFree) {
        this.form.authorizePrice = 0;
      }
    },
    // 详情弹窗
    detailsClick(val, index) {
      this.infoFilesPopup = true;
      this.valInfo = val;
      this.valInfo.index = index + 1;
    },
    // 添加作者
    authorChange() {
      let author = this.input;

      this.options.push({
        value: this.options.length,
        label: author,
      });

      this.inpshow = false;
      this.input = "";
    },
    //弹窗关闭回调
    editClose() {
      for (const i in this.dialogRuleForm) {
        this.dialogRuleForm[i] = "";
      }
    },
    editDialogClose() {
      for (const i in this.editForm) {
        this.editForm[i] = "";
      }
    },
    // 修改创作时间
    async amendPublishTime() {
      if (!this.dialogRuleForm.dialogDate) {
        return this.$message({
          type: "error",
          message: "请选择拟修时间",
        });
      }
      const currentData = new Date().getTime();

      const selectTime = new Date(
        this.dialogRuleForm.dialogDate + " 00:00:00"
      ).getTime();
      if (selectTime > currentData) {
        return this.$message({
          type: "error",
          message: "选择时间不能大于当前时间",
        });
      }
      var reg =
        /[http://|ftp://|https://|www]?[^\u4e00-\u9fa5\s]*?\.[com|net|cn|me|tw|fr][^\u4e00-\u9fa5\s]*/;

      // if (!reg.test(this.dialogRuleForm.proofImgUrl)) {
      //   return this.$message({
      //     type: "error",
      //     message: "请填写佐证材料网络地址",
      //   });
      // }
      // if (!this.proofMaterial) {
      //   return this.$message({
      //     type: "error",
      //     message: "请上传佐证图片",
      //   });
      // }
      const data = {
        portfolioId: this.id,
        updateTime: this.dialogRuleForm.dialogDate,
      };

      const res = await publishTime(data);
      // console.log(res);

      if (res.code === 200) {
        this.dialogVisible = false;
        this.dialogRuleForm.dialogDate = "";
        this.$message({
          type: "success",
          message: "提交成功",
        });
        // this.success_flag = true;
        // setTimeout(() => {
        //   this.success_flag = false;
        // }, 4500);
        this.getTableList();
      } else {
        this.$message({
          type: "error",
          message: "提交失败",
        });
      }
    },
    async findThemeClick(item, e) {
      // console.log(e);
      if (e?.target.nodeName === "INPUT") {
        return;
      }
      this.currentPrice = item.price;
      this.$set(this.form, "themeRadio", "");
      this.changeDiscipline(item.price);
      let childData = await worksTypeList(qs.stringify({ parentId: item.id }));
      console.log(childData);
      this.themeCategory = childData.data.portfolioTypes;
    },
    changeDiscipline(label) {
      // this.form.price = label*1;
      // this.form.authorizePrice = label*1;
      if (!this.priceIsFree) {
        this.$set(this.form, "price", label * 1);
      }
      if (!this.authorizePriceIsFree) {
        this.$set(this.form, "authorizePrice", label * 1);
      }
    },
    //限制输入框输入
    formatVal(val) {
      if (val > 30) {
        this.form.price = 30;
        return this.$message("最大为30元");
      }
      if (val == "0") {
        this.form.price = 1;
        return this.$message("最小为1元");
      }
      if (val.indexOf("-") != -1) {
        this.form.price = 1;
        return this.$message("价格不能为负数");
      }
      if (val.indexOf(".") != -1) {
        this.form.price = val.substring(0, val.indexOf("."));
        return this.$message("价格不能为小数");
      }
    },
    authorizeFormatVal(val) {
      if (val > 30) {
        this.form.authorizePrice = 30;
        return this.$message("最大为30元");
      }
      if (val == "0") {
        this.form.authorizePrice = 1;
        return this.$message("最小为1元");
      }
      if (val.indexOf("-") != -1) {
        this.form.authorizePrice = 1;
        return this.$message("价格不能为负数");
      }
      if (val.indexOf(".") != -1) {
        this.form.authorizePrice = val.substring(0, val.indexOf("."));
        return this.$message("价格不能为小数");
      }
    },
    // 修改作品价格
    async modifyPrice() {
      if (!this.priceChecked) {
        if (/^((-\d+(\.\d+)?)|(0+(\.0+)?))$/.test(this.priceInput)) {
          return this.$message({
            message: "授权价格不能为零或者负数",
          });
        }
        // console.log(this.priceInput > 99999999);
        if (this.priceInput > 30) {
          return this.$message({
            message: "超出设置的最大金额",
          });
        }

        if (String(this.priceInput).indexOf(".") != -1) {
          return this.$message({
            message: "价格不能是小数",
          });
        }
        if (this.priceInput == "") {
          return this.$message({
            message: "价格不能为空",
          });
        }
      }

      const data = {
        portfolioId: this.worksItem.id,
        setPrice: this.priceChecked ? 0 : 1,
        price: this.priceInput,
        authorizationType: "信息网络传播权",
        authorizationTerm: 1,
      };

      const res = await updatePrice(data);

      if (res.code === 200) {
        this.priceVisible = false;
        this.priceInput = "";
        this.$message({
          type: "success",
          message: "修改成功",
        });
        this.getTableList();
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },

    // 触发上传事件
    uploadFile() {
      this.$refs.backFile.value = "";
      this.$refs.backFile.dispatchEvent(new MouseEvent("click"));
    },
    async customPowerFile(files) {
      const { file } = files;
      console.log(file);
      const suffix = file.name.substring(file.name.lastIndexOf(".") + 1);
      if (suffix != "png" && suffix != "jpg" && suffix != "pdf") {
        this.$message({
          type: "error",
          message: "请选择png,jpg,pdf文件",
          customClass: "short-message",
          duration: 2000,
        });
        return;
      }
      this.uploadImgText = file.name;
      var fromData = new FormData();
      fromData.append("file", file);
      const res = await upload(fromData);
      if (res.code == 200) {
        this.$set(this.form, "imgUrl", res.data);
        this.$message({
          type: "success",
          message: "上传成功",
          customClass: "short-message",
          duration: 2000,
        });
      }
    },
    uploadClose() {
      for (let i in this.form) {
        this.form[i] = "";
      }
      this.isFirst = false;
      this.authorizePriceIsFree = false;
      this.priceIsFree = false;
    },
    // 上传图片
    async doRealUpload(e) {
      let file = e.target.files[0];
      this.erfile = file ? file.name : "";
      if (!/.(gif|jpg|jpeg|png|gif|jpg|png)$/.test(file.type)) {
        return this.$message({
          type: "error",
          message: "上传文件必须是图片",
        });
      }

      let form = new FormData();
      form.append("file", file);
      const res = await upload(form);
      if (res.code === 200) {
        this.proofMaterial = res.data;
        this.getTableList();
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }

      // console.log(res);
    },
    //获取列表信息
    async getTableList(id) {
      const data = {
        page: this.page,
        limit: this.limit,
        sort: this.sort,
        sortType: this.sortType,
        portfolioType: id,
      };
      const res = await getList(data);
      if (res.code === 200) {
        this.tableData = res.data.data;
        this.total = res.data.total;
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },

    findListById(id) {
      // this.currentIndex = index;
      this.currentId = id;
      this.getTableList(this.currentId);
    },
    ziSublime() {
      this.getTableList();
    },
    async getChild(item) {
      if (item?.children) {
        return;
      }
      const data = {
        parentId: item?.id,
      };
      const res = await worksTypeList(qs.stringify(data));
      if (res.code == 200) {
        if (!item) {
          this.sidebarArr = res.data;
        } else {
          this.$set(item, "children", res.data.portfolioTypes);
          // console.log(item);
        }
      }
    },
    //选择上下架操作
    handleShelves(row) {
      // console.log(row);
      if (row.isParse != 1) {
        return this.$message({
          type: "info",
          message: "该作品正在解析中",
          duration: 1000,
        });
      }
      this.$confirm(
        row.status == 1 ? "是否下架该作品" : "是否上架该作品",
        row.status == 1 ? "下架" : "上架",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(async () => {
          await this.upperOrLowerShelves(row.id, row.status);
          this.getTableList();
        })
        .catch(() => {});
    },
    /**
     * 上/下架作品请求
     * @param { Number } id 作品id
     * @param { Number } status 1-已上架 2未上架
     */
    async upperOrLowerShelves(id, status) {
      const data = {
        id,
        type: status == 1 ? 2 : 1,
      };
      const res = await updateShelves(data);
      if (res.code == 200) {
        this.$message({
          type: "success",
          message: status == 1 ? "下架成功" : "上架成功",
        });
      } else {
        return this.$message({
          type: "error",
          message: res.message,
        });
      }
    },

    async userPortfolio() {
      const data = {
        userId: "",
      };
      const res = await userPortfolioInfo(qs.stringify(data));
      if (res.code == 200) {
        console.log(res.data);
        this.userInfo = res.data;
      }
    },
    //  上一页
    lastPageChange(val) {
      this.page = val;
    },
    // page改变触发
    currentChange(cur) {
      this.page = cur;
      this.getTableList();
    },

    async getWorksTypeList(parentId, index, item) {
      // this.currentBtnIndex = index;
      const data = {
        type: 2,
      };
      const res = await menuSearchByType(qs.stringify(data));
      if (res.code == 200) {
        console.log(res.data);

        this.btnArr = res.data;

        this.btnFindChild(res.data[0], 0);
        //   this.portfolioType = parentId;
        //   //清空单选框
        //   this.disciplineRadio = "";
        //   this.themeRadio = "";
        //   this.className = item.title;
        //   this.disciplineCategory = res.data.disciplineCategory;
        //   this.themeCategory = res.data.themeCategory;
        // }
      }
    },

    async btnFindChild(item, index) {
      this.currentBtnIndex = index;
      this.className = item.title;

      switch (this.className) {
        case "模板":
          this.titleLabel = "模板标题";
          this.timeLabel = "发表时间";
          break;
        case "出版图书":
          this.titleLabel = "书名";
          this.timeLabel = "出版时间";
          break;
        case "文字作品":
          this.titleLabel = "文章标题";
          this.timeLabel = "发表时间";
          break;
        case "学位论文":
          this.titleLabel = "论文标题";
          this.timeLabel = "答辩时间";
          break;
        case "学术论文":
          this.titleLabel = "论文标题";
          this.timeLabel = "发表时间";
          break;
      }

      this.portfolioType = item.id;
      let childData = await worksTypeList(qs.stringify({ parentId: item.id }));

      this.disciplineCategory = childData.data.portfolioTypes.filter((item) => {
        return item.type == 3;
      });
      if (item.title == "学术论文") {
        this.themeCategory = childData.data.portfolioTypes.filter((item) => {
          return item.type == 4;
        });
      }
      if (item.title == "学位论文") {
        this.form.disciplineRadio = this.disciplineCategory[0].id;
        this.findThemeClick(this.disciplineCategory[0]);
      }

      this.templateCategory = childData.data.portfolioTypes.filter((item) => {
        return item.type == 2;
      });
    },
    // 下一页
    nextPageChange(val) {
      this.page = val;
    },
    // 获取当前日期
    getNowFormatDate() {
      var date = new Date();
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      var hour = date.getHours();
      var minu = date.getMinutes();
      var sec = date.getSeconds();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      if (hour >= 0 && hour <= 9) {
        hour = "0" + hour;
      }
      if (minu >= 0 && minu <= 9) {
        minu = "0" + minu;
      }
      if (sec >= 0 && sec <= 9) {
        sec = "0" + sec;
      }
      var currentdate = year + seperator1 + month + seperator1 + strDate;
      this.currenttime = hour + ":" + minu + ":" + sec;
      this.commitmentCurrentdate = currentdate;
      this.currentdate = currentdate;
    },

    // 修改作者弹窗
    handleAuthor(val) {
      this.authorVisible = true;

      // console.log(val);
      this.id = val.id;
    },

    //修改创造时间弹窗
    handlePublishTime(val) {
      this.dialogVisible = true;
      // console.log(val);
      this.getNowFormatDate();
      this.id = val.id;
    },

    // 修改授权价格弹窗
    handlePublishprice(val) {
      this.priceVisible = true;
      this.id = val.id;
      this.worksItem = val;
    },

    // 1 系统价格  2 输入价格
    handleChecked(val) {
      if (val == 1) {
        this.priceChecked = true;
        this.priceInputChecked = false;
      } else {
        this.priceChecked = false;
        this.priceInputChecked = true;
      }
    },
    //选择更多菜单
    selectMenu(i) {
      if (this.subIndex === i) {
        return (this.subIndex = -1);
      }
      this.subIndex = i;
    },
    clickBlankHandler() {
      this.subIndex = -1;
    },
    //认证弹窗
    goren(i) {
      //判断是否实名
      if (this.$store.state.realNameData == null) {
        this.ren_flag = true;
      } else {
        this.claimn_flag = true;
        this.rid = i;
      }
    },
    //关闭认证弹窗
    close() {
      this.ren_flag = false;
    },
    //去认证
    gorealName() {
      this.$router.push({ path: "realName" });
    },
    // 触发上传事件
    uploadFile1() {
      this.$refs.backFile.value = "";
      this.$refs.backFile.dispatchEvent(new MouseEvent("click"));
    },
    //认领上传文件
    async realUpload(e) {
      let file = e.target.files[0];
      this.flie = file ? file.name : "";
      let form = new FormData();
      form.append("file", file);
      const res = await upload(form);
      if (res.code === 200) {
        this.fileUrl = res.data;
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },
    //关闭认领弹窗
    closecl() {
      this.claimn_flag = false;
      //清空相关信息
      this.website = "";
      this.r_remark = "";
      this.fileUrl = "";
      this.flie = "";
      this.photoFlag = false;
      this.websitFlag = false;
      document.getElementById("photos").value == "";
    },
    //选择认领方式
    dian(e) {
      if (e.target.id == "websit") {
        this.websitFlag = true;
        this.photoFlag = false;
      }
      if (e.target.id == "photo") {
        this.photoFlag = true;
        this.websitFlag = false;
      }
    },
    //关闭成功提示
    shut() {
      this.succ_flag = false;
    },
    //关闭纠错弹窗
    closeer() {
      this.error_flag = false;
      //清空相关信息
      this.owner = "";
      this.websiter = "";
      this.remark = "";
      this.flie = "";
      this.fileUrl = "";
      document.getElementById("file").value == "";
    },
    // async workerInfo() {
    //   const res = await workerInfo();
    //   if (res.code == 200) {
    //     console.log(res.data);
    //     this.workerData = res.data;
    //   }
    // },
    //提交认领
    async submit() {
      //是否选择认领方式
      if (!this.websitFlag && !this.photoFlag) {
        this.$message.error("请选择认领方式");
        return;
      }

      if (!this.r_remark) {
        this.$message("请输入备注");
        return;
      }
      //是否选择网址佐证
      if (this.websitFlag) {
        //是否输入网址
        if (this.website == "") {
          this.$message.error("请输入网址");
          return;
        } else {
          //网址正则验证
          var reg =
            /[http://|ftp://|https://|www]?[^\u4e00-\u9fa5\s]*?\.[com|net|cn|me|tw|fr][^\u4e00-\u9fa5\s]*/;
          if (reg.test(this.website)) {
            //参数
            let data = {
              md5: this.tableData[this.rid].md5,
              applyType: 2,
              websiteUrl: this.website,
              remark: this.r_remark,
            };
            //发送请求
            let res = await apply(data);
            //判断响应是否成功
            if (res.code == 200) {
              //关闭弹窗
              this.claimn_flag = false;
              //清空相关信息
              this.website = "";
              this.r_remark = "";
              this.websitFlag = false;
              //显示成功弹窗
              this.succ_flag = true;
              setTimeout(this.shut, 3000);
            }
          } else {
            this.$message.error("请输入正确的网址格式");
            return;
          }
        }
      }
      //是否选择资料佐证
      if (this.photoFlag) {
        //是否上传资料
        if (this.fileUrl == "") {
          this.$message.error("请上传照片或资料");
          return;
        }

        //参数
        let data = {
          md5: this.tableData[this.rid].md5,
          applyType: 3,
          fileUrl: this.fileUrl,
          remark: this.r_remark,
        };
        //发送数据
        let res = await apply(data);
        if (res.code == 200) {
          this.claimn_flag = false;
          this.succ_flag = true;
          this.r_remark = "";
          this.flie = "";
          this.fileUrl = "";
          this.photoFlag = false;
          document.getElementById("photos").value == "";
          setTimeout(this.shut, 3000);
        }
      }
    },
    //纠错提交
    async submiter() {
      //是否输入著作权人
      if (this.owner == "") {
        this.$message.error("请输入准确著作权人");
        return;
      }
      //如果输入网址
      if (this.websiter != "") {
        //网址正则验证
        var reg =
          /[http://|ftp://|https://|www]?[^\u4e00-\u9fa5\s]*?\.[com|net|cn|me|tw|fr][^\u4e00-\u9fa5\s]*/;

        if (!reg.test(this.websiter)) {
          this.$message.error("请输入正确的网址格式");
          return;
        }
      }
      //参数
      let data = {
        md5: this.tableData[this.rid].md5,
        currentUserId: this.tableData[this.rid].originalId,
        currentAuthor: this.owner,
        websiteUrl: this.websiter,
        fileUrl: this.fileUrl,
        remark: this.remark,
      };
      //发送数据
      let res = await error(data);
      //是否响应成功
      if (res.code == 200) {
        this.error_flag = false;
        this.succ_flag = true;
        this.owner = "";
        this.websiter = "";
        this.remark = "";
        this.flie = "";
        this.fileUrl = "";
        document.getElementById("file").value == "";
        setTimeout(this.shut, 3000);
      }
    },
  },
  created() {
    this.getTableList();
    this.getNowFormatDate();
    this.getChild();
    this.getWorksTypeList(undefined, 0, [{ title: "" }]);
    // this.workerInfo();
    this.userPortfolio();
  },
  mounted() {
    document.addEventListener("click", this.clickBlankHandler);
  },
  destroyed() {
    document.removeEventListener("click", this.clickBlankHandler);
  },
};
</script>

<style lang="scss" scoped>
.isshow {
  width: 1000px;
  flex: 1 0 1020px;
}
.outBox {
  display: flex;
  background: #f9f9f9;
}
.right {
  background-color: #fff;
  flex: 1;
}
.centerList {
  width: 936px;
  margin-right: 20px;
  background-color: #fff;
  .qrcode {
    ::v-deep .el-dialog {
      // top: 50%;
      // transform: translateY(-50%);
      margin-top: 20vh !important;
      width: 500px;
      height: 390px;
      background: #ffffff;
      border: 0px solid #818790;
      box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
      .el-dialog__header {
        height: 42px;
        background: #f4f6f7;
        padding: 0;
        line-height: 42px;
        .el-dialog__title {
          margin-left: 15px;
          font-size: $font-size-medium;
          font-family: PingFang SC;
          font-weight: 500;
          color: #393939;
        }
        .el-dialog__headerbtn {
          top: $font-size-small;
          right: $font-size-small;
          font-size: $font-size-large;
        }
      }
      .el-dialog__body {
        padding: 65px 20px;
        .content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .notes {
            font-size: $font-size-medium;
            font-family: PingFang SC;
            font-weight: 500;
            color: $color-text-black;
          }
          .imgBox {
            margin-top: 30px;
          }
        }
      }
    }
  }
  .back_box {
    border-top: 1px solid #e9ebf2;
    border-bottom: 1px solid #e9ebf2;
    background-color: #fbfcfd;
    height: 30px;
    padding: 0 0 0 10px;
    line-height: 30px;
    .back {
      cursor: pointer;
      font-size: 12px;
    }
  }
  // flex: 1;
  box-sizing: border-box;
  border-right: 1px solid #eceff1;
  position: relative;
  // display: flex;
  .user-info {
    padding: 15px;
    border-top: 1px solid #e9ebf2;
    position: relative;
    .upload {
      position: absolute;
      right: 15px;
      top: 15px;
      font-size: 14px;
      color: #4587ff;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    .AuthorizationList {
      position: absolute;
      right: 115px;
      top: 15px;
      font-size: 14px;
      color: #4587ff;
      display: flex;
      align-items: center;
      cursor: pointer;
      img {
        width: 16px;
        height: 16px;
      }
    }
    .info {
      width: 100%;
      height: 70px;
      // box-sizing: border-box;
      display: flex;
      align-items: center;
      .left {
        display: flex;
        flex-direction: column;
        margin-left: 25px;
        .collection {
          font-size: 16px;
          color: #999999;
        }
        .focus {
          color: #999999;
          font-size: 12px;
        }
        // align-items: center;
        // justify-content: center;
        > div {
          flex: 1;
        }
        line-height: 35px;
        height: 70px;
      }
      > img {
        width: 63px;
        height: 63px;
        border: 1px solid #e9ebf2;
        border-radius: 50%;
      }
    }
    .cards {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      > div {
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // flex: 1;
        width: 204px;
        box-sizing: border-box;
        height: 80px;
        background: #ffffff;
        box-shadow: 0px 2px 7px 0px rgba(11, 41, 85, 0.08);
        border-radius: 8px;
      }
      img {
        width: 32px;
        height: 32px;
      }
      .label {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        > div {
          line-height: 1.5;
        }
        .title {
          color: #333333;
          font-size: 14px;
          font-weight: bold;
        }
        .value {
          font-weight: bold;
          font-size: 13px;
          color: #999999;
        }
      }
    }
  }
  .mian {
    display: flex;
    .sidebar {
      width: 170px;
      // height: calc(100vh - 51px);
      height: calc(100vh - 283px);
      // overflow-y: auto;
      border-right: solid 1px #e6e6e6;
      overflow: hidden;

      .el-menu {
        width: 170px;
        .el-menu-item {
          // padding: 0 !important;
          min-width: 170px;
          box-sizing: border-box;
        }
      }

      .el-menu-item.is-active {
        border-right: 3px solid #4587ff;
      }
      ::v-deep .el-menu .el-menu-item,
      el-submenu {
        height: 40px;
        line-height: 40px;
      }
      .item {
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-size: 14px;
        cursor: pointer;
      }
    }
    .content {
      flex: 1;
      overflow: auto;
      height: calc(100vh - 283px);
      .header {
        height: 40px;
        line-height: 40px;
        // text-align: center;
        position: relative;
        background-color: #ecf5ff;
        .upload-new-file {
          margin-left: 10px;
          font-size: 14px;
          display: inline-block;
          color: #4587ff;
          cursor: pointer;
          // width: 100px;
          // display: flex;
          // align-items: center;
          // .add {
          //   font-size: 18px;
          // }
        }
        .authorization {
          color: #40be8a;
          font-size: 14px;
          margin-left: 90px;
          display: inline-block;
          cursor: pointer;
        }

        .refresh {
          color: #4587ff;
          font-size: 14px;
          margin-left: 30px;
          display: inline-block;
          cursor: pointer;
        }
        .works {
          font-size: 15px;
          display: inline-block;
          font-weight: bold;
          cursor: pointer;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translateY(-50%) translateX(-50%);
        }
        .num {
          font-size: 12px;
          color: #666666;
          position: absolute;
          right: 10px;
          top: 50%;
          line-height: normal;
          transform: translateY(-50%);
        }
      }
      .title-option {
        // margin: 10px 0;
        position: relative;
        padding: 10px 13px;
        div {
          display: inline-block;
          cursor: pointer;
        }
        img {
          vertical-align: middle;
        }
        // .upload-new-file {
        //   margin-left: 10px;
        //   font-size: 14px;
        //   color: #4587ff;
        // }
        // .authorization {
        //   color: #40be8a;
        //   font-size: 14px;
        //   margin-left: 90px;
        // }
        .sort {
          // transform: translateY(-50%);
          .sort_item {
            margin-right: 20px;
            font-size: 12px;
            color: #666;
            .is-active {
              color: #4587ff;
            }
            i {
              display: inline-block;
              width: 9px;
              height: 13px;
              margin-left: 6px;
            }
            .up {
              @include backgroundGroup("~assets/images/up.png");
            }

            .down {
              @include backgroundGroup("~assets/images/down.png");
            }
          }
        }
      }
      .tableContent {
        padding: 10px;
        // height: calc(100vh - 122px);
        font-size: 14px;
        .table_item {
          position: relative;
          cursor: pointer;
          // padding-top: 10px;
          // box-shadow: 0px 0px 10px 2px #eee;
          border-bottom: 0.0625rem solid #efefef;
          margin: 6px 0;
          padding: 10px 5px;
          display: flex;
          align-items: center;
          box-sizing: border-box;
          // border-bottom: 1px #ccc solid;
          min-width: 650px;
          white-space: nowrap;
          &:hover {
            background: #ffffff;
            box-shadow: 3px 1px 16px 2px rgba(107, 131, 167, 0.1);
            border-radius: 5px;
          }
          > img {
            width: 60px;
            height: 60px;
          }
          .main {
            width: 80%;
            flex: 1;
          }
          .states {
            display: inline-block;
            width: 50px;
            height: 18px;
            // margin-top: -9px;
            font-size: 12px !important;
            // font-weight: 800;
            text-align: center;
            line-height: 18px;
            color: #999;
            z-index: 1;
            position: absolute;
            left: 11px;
            bottom: 15px;
            &::before {
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              display: block;
              width: 100%;
              height: 100%;
              z-index: -1;
            }
          }

          .gray {
            // background: url("~assets/images/rhombus-gray.png") no-repeat;

            color: #999;
          }
          .green {
            // background: url("~assets/images/rhombus-green.png") no-repeat;
            color: #4587ff;
          }
          .orange {
            // background: url("~assets/images/rhombus-orange.png") no-repeat;
            color: orange;
          }
          .eparsed {
            // width: 62px;
            color: rgb(192, 0, 0);
            // background: url("~assets/images/rhombus-red.png") no-repeat;
          }
          .hasparse {
            // width: 75px;
            color: rgb(192, 0, 0);
            // background: url("~assets/images/rhombus-red-long.png") no-repeat;
          }
          .title {
            width: 50%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            .main-title {
              // margin-left: 20px;
              height: 30px;
              line-height: 30px;
            }

            padding-left: 20px;
            height: 30px;
            line-height: 30px;
            font-size: 14px;
            // font-weight: bold;

            // border-left: 3px solid #4587ff;
          }
          .classfiy {
            position: absolute;
            top: 10px;
            right: 10px;
            font-size: 13px;
            // background-image: linear-gradient(to right, #bbb, gray);
            background-color: #4587ff;
            border-radius: 5px;
            color: #fff;
            padding: 5px;
          }
          .info {
            color: gray;
            padding-left: 20px;
            height: 40px;
            line-height: 40px;
            font-size: 13px;

            // color: #333333;
            // img {
            //   width: 50px;
            //   height: 50px;
            //   vertical-align: middle;
            // }
            div:first-child {
              text-align: left;
            }
            div {
              display: inline-block;
              width: 23%;
              text-align: center;
            }
            .more {
              cursor: pointer;
              vertical-align: middle;
            }
          }
        }
        // .table_item:hover {
        //   background-color: #eee;
        // }
      }
    }
  }

  .centerList-title {
    height: 45px;
    text-align: center;
    line-height: 45px;
    font-size: $font-size-medium;
    font-family: PingFang SC;
    font-weight: 500;
    color: $color-text-gray-s;
    border-bottom: 1px solid #eceff1;
    position: relative;

    .copyrightIncome-item {
      width: 130px;
      height: 37px;
      color: #4587ff;
      border-radius: 3px;
      font-size: $font-size-small;
      font-family: PingFang SC;
      font-weight: 400;

      background: none;
      cursor: pointer;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 10px;
    }

    .start {
      color: $color-text-white;
      background: $color-bg-heightlight;
    }
  }
  ::v-deep .el-table {
    overflow: visible;
    .el-table__body-wrapper {
      overflow: visible;
    }
    .el-table__cell {
      overflow: hidden;
      &:last-child {
        overflow: visible;
        .cell {
          overflow: visible;
        }
      }
    }
    .cell {
      overflow: visible;
      position: relative;
      .states {
        position: absolute;
        left: 30px;
        top: 50%;
        width: 50px;
        height: 18px;
        margin-top: -9px;
        font-size: 12px;
        font-weight: 800;
        text-align: center;
        line-height: 18px;
        color: #fff;
        z-index: 1;
        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          display: block;
          width: 100%;
          height: 100%;
          z-index: -1;
        }
      }

      .gray {
        &::before {
          background: url("~assets/images/rhombus-gray.png") no-repeat;
        }
        color: #999;
      }
      .green {
        &::before {
          background: url("~assets/images/rhombus-green.png") no-repeat;
        }
      }
      .orange {
        &::before {
          background: url("~assets/images/rhombus-orange.png") no-repeat;
        }
      }
      .eparsed {
        // width: 62px;
        &::before {
          background: url("~assets/images/rhombus-red.png") no-repeat;
        }
      }
      .hasparse {
        // width: 75px;
        &::before {
          background: url("~assets/images/rhombus-red-long.png") no-repeat;
        }
      }

      .operation {
        @include flex-start;
        > span {
          flex-shrink: 0;
          width: 14px;
          height: 14px;
          cursor: pointer;
          margin-right: 10px;
        }
        .more {
          position: relative;
          @include backgroundGroup("~assets/images/more.png");
          .submenu {
            @include noselect;
            &.submenu-active {
              @include flex-center(column);
              justify-content: normal;
              align-items: normal;
            }
            display: none;
            position: absolute;
            z-index: 1000;
            top: 20px;
            left: 0px;
            width: 114px;
            padding: 8px 0;
            background: #ffffff;
            box-shadow: 0px 4px 8px 1px rgba(31, 34, 40, 0.17);
            border-radius: 5px;
            box-sizing: border-box;
            span {
              padding: 7px 15px 6px;
              font-size: 12px;
              font-weight: 500;
              color: #999999;
              line-height: 1;
              cursor: pointer;
              &:hover {
                color: #666;
                background-color: #f5f6fa;
              }
            }
          }
        }
        .more:hover {
          @include backgroundGroup("~assets/images/more-blue.png");
        }
        .delete {
          @include backgroundGroup("~assets/images/delete.png");
        }
        .delete:hover {
          @include backgroundGroup("~assets/images/delete-blue.png");
        }
      }
      .fileName {
        cursor: pointer;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        align-items: center;
      }
      // .author,
      // .price {
      //   justify-content: space-between;
      // }
      .price,
      .author,
      .publishTime {
        display: flex;
        align-items: center;
        .modifyIcon {
          margin-left: 5px;
          width: 13px;
          height: 12px;
          cursor: pointer;
          // @include backgroundGroup("../../assets/images/modify.png");
        }
      }
    }
  }
  ::v-deep .el-pagination {
    text-align: center;
    margin: 20px 0;
    .btn-prev,
    .btn-next {
      span {
        min-width: 60px;
      }
    }
  }

  .text {
    margin-bottom: 20px;
  }

  .upimg {
    color: #4587ff;
    border: 1px solid #dcdfe6;
    padding: 0 0 0 15px;
    cursor: pointer;
  }

  .select {
    display: flex;
    justify-content: center;
    align-items: center;
    .text {
      margin-bottom: 0;
      height: 100%;
      flex: 20%;
    }
  }

  .addauthor_box {
    display: flex;

    .placeholder {
      // padding: 20px;
      flex: 15%;
    }

    .addauthor {
      margin: 20px 0 10px 0;
      flex: 80%;
      text-align: left;
      color: #4587ff;
      cursor: pointer;
    }
  }

  // 修改授权价格弹窗
  .priceVisible {
    .cash {
      .text {
        margin: 10px 0;
        line-height: 20px;
        color: #000;
      }
    }

    .shape {
      margin: 20px 0;
    }
    .checked {
      margin-top: 10px;
      .checked_box {
        .checkbox_content {
          width: 99%;
          display: inline-grid;
          white-space: pre-line;
          word-wrap: break-word;
          overflow: hidden;
          line-height: 20px;
        }
      }
      .el-checkbox {
        display: flex;
        align-items: center;
        width: 100%;
        margin: 10px 0 0 0;
      }

      .el-input {
        margin-top: 20px;
        ::v-deep .el-input__inner::-webkit-outer-spin-button,
        ::v-deep .el-input__inner::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }

        ::v-deep .el-input__inner[type="number"] {
          -moz-appearance: textfield;
        }
      }
    }
  }

  .no-document {
    margin: 100px 0;

    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      // text-align: center;
      img {
        width: 16px;
        height: 16px;
      }
    }
    .content_item {
      width: 80%;
      margin: 0 auto;
      > div {
        text-indent: 2em;
        font-size: 14px;
        margin: 20px 0;
        .label {
          font-size: 14px;
          color: #333;
          font-weight: bold;
        }
      }
    }
  }

  .success {
    width: 391px;
    background: #ffffff;
    border: 1px solid #4587ff;
    border-radius: 22px;
    position: absolute;
    top: 600px;
    left: 41%;
    box-sizing: border-box;
    padding: 16px 21px;
    span {
      width: 349px;
      height: 67px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 26px;
    }
    img {
      position: relative;
      top: 4px;
      margin-right: 10px;
    }
  }

  // 详情弹窗
  .addFil {
    margin: 0 auto 50px;
    width: 70%;
    ::v-deep .el-dialog {
      width: 100%;
    }
    .box {
      margin-top: 20px;

      &:nth-child(1) {
        margin-top: 0;
        border-bottom: 1px solid #ccc;
        padding-bottom: 20px;

        display: flex;

        span {
          &:nth-child(2) {
            flex: 1;
          }
        }

        .download {
          width: 20px;
          height: 20px;
          cursor: pointer;
        }
      }
    }
  }

  // 版权真实性承诺样式
  .copyrightVisible {
    ::v-deep .el-dialog {
      background-image: url("~@/assets/images/signbg.png");
      background-size: 100% 100%;
      .el-dialog__header {
        text-align: center;
        font-weight: bold;
        padding: 85px 81px 10px;
      }
      .el-dialog__body {
        padding: 30px 134px;
      }
      .el-dialog__footer {
        padding: 30px 134px 74px;
        text-align: center;
      }
    }
    .text {
      line-height: 20px;
      margin-bottom: 20px;
    }

    .signature_box {
      display: flex;
      justify-content: center;
      align-items: center;

      .signature_text {
        flex: 20%;
      }

      .signature {
        padding: 10px;
        // border: 1px solid #e3e3e3;
        flex: 80%;
        color: #4587ff;
        cursor: pointer;
      }
    }

    .authorSignatureImg {
      margin: 0 auto;
      display: block;
      width: 190px;
      height: 320px;
      transform: rotate(-90deg);
    }

    .commitment_box {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;

      .commitmentText {
        flex: 20%;
      }

      .commitmentDate {
        flex: 25%;
      }

      .commitmentTime {
        flex: 55%;
      }
    }
  }

  .editDialog {
    ::v-deep .el-dialog {
      .el-dialog__body {
        .el-form {
          .el-form-item {
            .el-form-item__label {
              width: 80px !important;
            }
            .el-form-item__content {
              margin-left: 80px !important;
              .el-input {
                .el-input__inner::-webkit-input-placeholder {
                  /* 使用webkit内核的浏览器 */
                  // color: #e0484b;
                  line-height: 40px;
                }
              }
            }
          }
        }
      }
    }
  }
  .uploadVisible {
    ::v-deep .el-dialog {
      margin: 1vh auto 0 auto !important;
      width: 700px !important;
      .el-dialog__heade {
        padding: 0;
      }
      .el-dialog__body {
        .subjectType {
          font-family: "PingFang SC";
          color: #000;
          font-weight: bold;
        }
        .themeType {
          font-family: "PingFang SC";
          color: #000;
          font-weight: bold;
        }
        .nation {
          padding-left: 105px;
          margin-bottom: 20px;
        }
        .el-form-item {
          position: relative;
          .custom-price {
            cursor: pointer;
            color: #4587ff;
            font-family: "PingFang SC";
            font-weight: bold;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
          }
          .click-upload {
            cursor: pointer;
            color: #4587ff;
            font-family: "PingFang SC";
            font-weight: bold;
            position: absolute;
            left: 10px;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .authorization,
          .main-title {
            width: 100%;
            border: 1px solid #dcdfe6;
            box-sizing: border-box;
            border-radius: 4px;
            height: 40px;
            line-height: 40px;
            font-size: inherit;
          }
          .upload-demo {
            position: absolute;
            left: 10px;
            top: 50%;
            transform: translateY(-50%);
            width: 90%;
          }
          .upload-power {
            position: absolute;
            left: 10px;
            top: 50%;
            transform: translateY(-50%);
            width: 90%;
          }
          .el-input__inner::-webkit-input-placeholder {
            /* 使用webkit内核的浏览器 */
            // color: #e0484b;
            line-height: 40px;
          }
          .priceClass {
            .el-input__inner {
              border-radius: 4px 0 0 4px;
            }
          }
          .wordTypeSelect {
            .el-input__inner {
              border-radius: 4px 0 0 4px;
            }
          }
          .wordTypeInput {
            .el-input__inner {
              border-left: none;
              border-radius: 0;
            }
          }
        }
        .customWidth {
          .el-form-item__content {
            border: 1px solid #dcdfe6;
            border-radius: 4px 0 0 4px;
          }
          .el-button {
            border-radius: 0 4px 4px 0;
          }
        }
        .customBorder {
          .el-form-item__content {
            border: 1px solid #dcdfe6;
            border-radius: 4px;
          }
        }
        .el-form-item__label {
          font-family: "PingFang SC";
          color: #000;
          font-weight: bold;
          width: 90px !important;
        }
        .el-form .el-form-item .el-form-item__content {
          margin-left: 100px !important;
          // .el-input .el-input__suffix .el-input__icon{
          //   background-image: url('');
          // }
          .el-radio-group {
            display: flex !important;
            flex-wrap: wrap;
            width: 100%;
            margin-top: 8px;
            // height: 100%;
            // height: 40px;
            // line-height: 40px;
            .el-radio {
              width: 25%;
              margin: 5px 0;
            }
          }
          .isSelect {
            border: 1px solid #4587ff;
            color: #4587ff;
          }
        }
      }
    }
  }

  // 上传弹窗
  .upload {
    ::v-deep .el-dialog {
      width: 55%;
      min-height: 340px;
      background: #ffffff;
      border: 0px solid #818790;
      box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);

      .el-dialog__header {
        height: 42px;
        background: #f4f6f7;
        padding: 0;
        display: flex;
        align-items: center;

        .el-dialog__title {
          margin-left: 10px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #393939;
        }

        .el-dialog__headerbtn {
          top: 15px;
        }
      }

      .el-dialog__body {
        padding: 27px 30px 0px;

        .content-text {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 24px;

          .title {
            text-align: center;
          }

          .demand_box {
            display: flex;
            margin-top: 20px;
            align-items: center;
            .demand_text {
              width: 110px;
              font-size: 14px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #333333;
            }

            .demand_inp {
              cursor: pointer;
              flex: 1;
              padding: 10px;
              color: #4587ff;
              border: 1px solid #e3e3e3;
            }
          }

          .choose_data_box {
            display: flex;
            margin-top: 20px;

            .choose_text {
              width: 110px;
              font-size: 14px;
              font-family: PingFang SC;
              font-weight: 500;

              span {
                color: red;
              }
            }

            .choose_radio {
              .choose_item {
                display: flex;
                align-items: center;
                margin-bottom: 5px;

                input {
                  cursor: pointer;
                }

                label {
                  padding-left: 10px;
                  cursor: pointer;
                }
              }
            }
          }

          .choose_price_box {
            display: flex;
            margin-top: 20px;

            .choose_text {
              width: 110px;
              font-size: 14px;
              font-family: PingFang SC;
              font-weight: 500;

              span {
                color: red;
              }
            }

            .choose_radio {
              flex: 1;
              .choose_item {
                display: flex;
                align-items: center;
                margin-bottom: 5px;

                input {
                  cursor: pointer;
                }

                label {
                  padding-left: 10px;
                  cursor: pointer;
                }
              }

              .el-input {
                margin-top: 5px;
                .el-input__inner {
                  border-radius: 0px;
                }

                .el-input__inner::-webkit-outer-spin-button,
                .el-input__inner::-webkit-inner-spin-button {
                  -webkit-appearance: none;
                }

                .el-input__inner[type="number"] {
                  -moz-appearance: textfield;
                }
              }
            }
          }
        }
      }
    }
  }

  // 版权文字作品上传须知弹窗
  .uploadNotice {
    ::v-deep .el-dialog {
      margin-top: 5vh !important;
      width: 680px;
      min-height: 340px;
      background: #ffffff;
      border: 0px solid #818790;
      box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);

      .el-dialog__header {
        height: 42px;
        background: #f4f6f7;
        padding: 0;
        display: flex;
        align-items: center;

        .el-dialog__title {
          margin-left: 10px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #393939;
        }

        .el-dialog__headerbtn {
          top: 15px;
        }
      }

      .el-dialog__body {
        padding: 20px 30px 20px;
        .content-text {
          line-height: 2;
          .title {
            text-indent: 2em;
            font-size: 14px;
            font-weight: 800;
          }

          .text_box,
          .sequence_txt {
            text-indent: 2em;
          }
        }
      }
    }
  }
}

::v-deep .el-table__empty-block {
  display: none;
}
.claim {
  width: 340px;
  height: 200px;
  background: #ffffff;
  box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
  position: fixed;
  top: 320px;
  left: 750px;
  .close {
    margin-top: 10px;
    margin-left: 317px;
    cursor: pointer;
  }
  .renzheng {
    display: flex;
    align-items: center;
    margin-left: 84px;
    margin-top: 41px;
    img {
      display: block;
      margin-right: 9px;
    }
    span {
      display: block;
      width: 160px;
      height: 16px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
    }
  }
  .xuanze {
    display: flex;
    margin-top: 60px;
    .yes {
      width: 64px;
      height: 16px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #4587ff;
      margin-left: 54px;
      margin-right: 50px;
      cursor: pointer;
    }
    .no {
      margin-left: 65px;
      height: 15px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #4587ff;
      cursor: pointer;
    }
    .xian {
      width: 1px;
      height: 20px;
      border-left: 1px solid #e8e8e8;
    }
  }
}
.claimn {
  z-index: 10;
  width: 428px;
  background: #ffffff;
  border: 0px solid #818790;
  box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .claimnNav {
    display: flex;
    width: 427px;
    height: 42px;
    background: #f4f6f7;
    .title {
      display: flex;
      align-items: center;
      margin-left: 10px;
      span {
        margin-left: 5px;
      }
    }
    .title_x {
      position: absolute;
      top: 14px;
      right: 10px;
      cursor: pointer;
      width: 14px;
      height: 14px;
    }
  }
  .wangzhi {
    margin-top: 21px;
    margin-left: 25px;
    cursor: pointer;
    label {
      width: 55px;
      height: 14px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #666666;
      margin-left: 10px;
      cursor: pointer;
    }
  }
  .w-title {
    height: 14px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 28px;
    display: block;
    margin-top: 20px;
    margin-left: 30px;
  }
  .shuruw {
    width: 366px;
    height: 50px;
    background: #ffffff;
    border: 1px solid #cecece;
    border-radius: 5px;
    margin-top: 20px;
    margin-left: 30px;
    box-sizing: border-box;
    padding: 18px 20px;
    display: block;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #999999;
  }
  textarea {
    width: 366px;
    height: 100px;
    background: #ffffff;
    border: 1px solid #cecece;
    border-radius: 5px;
    margin-top: 20px;
    margin-left: 30px;
    box-sizing: border-box;
    padding: 18px 20px;
    display: block;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #999999;
    resize: none;
  }
  .file-tip {
    width: 366px;
    height: 50px;
    background: #ffffff;
    border: 1px solid #cecece;
    border-radius: 5px;
    margin-top: -50px;
    margin-left: 30px;
    box-sizing: border-box;
    padding: 18px 20px;
    display: block;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #4587ff;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .botton {
    display: flex;
    margin-left: 240px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
</style>
